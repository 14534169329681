
#partner-logo-header {
    height: 36px;
    width: 90px;
    background: no-repeat center;
    flex: 1 1 auto;

    @media (min-width: 767px) {
        display: none;
    }
}
