
@import "@/scss/font";
@import "@/scss/color";
@import "@/scss/breakpoints";
@import "@/scss/helper/_mixin";

.register-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .register-content {
        position: relative;
        width: 100%;
        height: auto;
        max-width: 387px;
        margin: 0 auto;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        margin: auto;

        select::-ms-expand {
            display: none;
        }

        .blur-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            // filter: blur(10px);
            //background: url('/images/backgrounds/epix-background-image.jpg');
            background-repeat: no-repeat;
            background-size: 100% 92%;
            background-position: 0 86px;
            background-attachment: fixed;
        }

        h1.form-header {
            font-size: 43px;
            font-weight: bold;
            color: #000;
            padding: 20px 0;
            max-height: 83px;
        }

        .form-container {
            width: 100%;
            max-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
            }
            .error {
                width: 100%;
                font-size: 12px;
                color: #ccaf5f;
            }
            &.form-body > * {
                padding: 0 1.5rem 0;

                @media (min-width: map-get($grid-breakpoints, "sm")) {
                    padding: 0 69px 0;
                }

                &.register-footer {
                    padding-bottom: 0;
                    padding-top: 0px;
                    line-height: 55px;
                    font-size: 16px;
                    margin-top: 15px;
                }

                .form {
                    // min-height: 396px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                .form * {
                    margin: 5px 0;
                }

                input {
                    width: 100%;
                    height: 40px;
                    font-size: 16px;
                    text-align: center;
                    border: none;
                    @include input-outline;
                }

                .form-group {
                    height: 44px;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 0;
                }

                select {
                    height: 44px;
                    line-height: 44px;
                    width: 100%;
                    margin: 0;
                    background: url("../../assets/images/select-arrow-black.png")
                        no-repeat top 17px right 17px;
                    background-color: #ffffff;
                    -webkit-appearance: none;
                    appearance: none;
                    -webkit-border-radius: 0px;
                    border-radius: 0px;
                    box-sizing: border-box;
                    font-size: 16px;
                    border: none;
                    @include input-outline;
                }

                .disabled {
                    color: #757575;
                    background: url("../../assets/images/select-arrow.png")
                        no-repeat top 17px right 17px;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    margin: 0;
                }

                .select {
                    margin: 5px 0;
                }

                .input-container {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .input-label {
                    padding-left: 7px;
                    font-weight: normal;
                }

                .custom-checkbox {
                    cursor: pointer;
                    border: none;
                    outline: 2.5px solid rgba(0, 0, 0, 0.3);
                    background-color: #ffffff;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    height: 20px;
                    width: 20px;
                }

                .custom-checkbox:checked {
                    background: url("../../assets/images/checkmark.svg")
                        no-repeat top 2px right 2px;
                    background-color: #ffffff;
                    background-size: 80%;
                }

                input.half,
                select.half {
                    width: calc(50% - 10px);
                }
                button.half,
                input[type="submit"].half {
                    width: calc(50% - 6px);
                    min-width: auto;
                    padding-left: 0;
                    padding-right: 0;
                }
                button,
                input[type="submit"] {
                    display: inline-block;
                }
                .register-footer {
                    margin: -5px 0 0 0;
                    color: $text-footer;
                }

                .full-width {
                    width: 100%;
                }
                .left {
                    float: left;
                }
                .right {
                    float: right;
                }
            }
        }

        .register-footer {
            padding: 13px 0 0 0;
            margin: 0px;
            font-size: 14px;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }
        .loginfooter {
            margin-left: 6px;
        }
        .copy {
            margin-top: -15px;
            margin-botton: 10px;
            padding: 8px 15px;
        }
    }
}
.dropdowntext {
    text-align: center;
    text-align-last: center;
}
.list {
    padding-right: 35px;
}
