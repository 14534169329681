$gold-light: #e0c479;
$gold-medium: #c7aa59;
$gold-dark: #8d6f2f;
$silver: #ecedeb;

$primary-color: $gold-medium;
$secondary-color: #333333;
$error-color: red;
$text-header: #000;
$text-primary: #000;
$text-footer: #000;

$background-color: $gold-light;

$font-placeholder: #aeaeae;

$link-color: white;

$button-grey: #ccaf5f;


$yellow: rgb(255, 222, 0);
$bg-blue: rgba(0, 102, 221, 1);

$grey: #ebebeb;

$carousel-button: #333333;

$button-blue: #0571b8;

$background-grey: #ccaf5f;

$module-summary-circle-locked: lightgrey;
$module-summary-circle: white;

$quiz-summary-circle-locked: $gold-light;
$quiz-summary-circle: $gold-dark;

$nav-summary-background: $silver;
$nav-summary-circle-locked: #000;
$nav-summary-circle-active: $gold-dark;
$nav-summary-circle: #000;

$support-border-grey: #d2d2d2;
