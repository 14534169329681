
@import "./scss/base.scss";
@import "~../node_modules/vex-js/dist/css/vex.css";
@import "~../node_modules/vex-js/dist/css/vex-theme-plain.css";

body {
    display: block !important;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: $text-primary;
    height: 100%;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

#main-page {
    height: 100%;
}

.router-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}
