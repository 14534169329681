
.module.activity-pdf {
    max-width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    flex-direction: row;
    align-items: stretch;
    padding: 0;

    .content-container {
        align-items: center;

        // Need extra spacing to not run into absolutely positioned nav summary
        padding-top: 80px;
    }

    a {
        text-decoration: none !important;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
        font-size: 16px;
    }
    .center-item {
        width: 190px;
        height: 190px;
        position: fixed;
    }

    .image-splash {
        max-width: 100%;
        max-height: 100%;
    }

    .image-button-container {
        padding-top: 25px;
        padding-bottom: 21px;
        max-width: 508px;
        width: 100%;
        margin: 20px !important;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .view-pdf {
        text-decoration: none;
    }
}
