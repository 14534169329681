
@import "@/scss/color";
@import "@/scss/breakpoints";
@import "@/scss/helper/_mixin";

.profile-popup {
    // This box sizing should be global!!!
    * {
        box-sizing: border-box;
    }

    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s;
    display: flex;

    &.open {
        opacity: 1;
        visibility: visible;
    }

    &.open:before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 70%);
        pointer-events: none;
        z-index: -1;
    }

    .profile-popup--dialog {
        position: relative;
        width: 95%;
        border: 1px solid black;
        background-color: $silver;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up("sm") {
            padding: 4rem 4rem 2rem 4rem;
        }

        @include media-breakpoint-up("md") {
            width: 650px;
        }

        .close {
            color: black;
            font-weight: bold;
            position: absolute;
            top: 0;
            right: 0;
            padding: 1rem;
            cursor: pointer;
            text-decoration: none;

            @include input-outline;
        }

        .heading {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 1.4375rem;

            @include media-breakpoint-up("sm") {
                font-size: 21px;
            }
        }

        @include media-breakpoint-up("sm") {
            .form-group--state {
                flex-basis: 50%;
            }
        }
    }
}
