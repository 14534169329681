
@import "@/scss/font";
@import "@/scss/color";

.module.final {
    text-align: center;

    .outer-section {
        width: 100%;
        max-width: 820px;
    }

    p {
        font-size: 12px;
        line-height: 1.1;
    }

    .buttons {
        margin-top: 10px;
    }

    .inner-section {
        width: 100%;
        max-width: 600px;
        background-color: $primary-color;
        border-radius: 8px;
        padding: 10px 30px 20px 30px;
        margin-top: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            @extend .final-secondary-heading;
        }

        h2,
        p {
            width: 100%;
        }
    }

    .accept-button {
        background-color: $grey;
        color: $primary-color;
        border-radius: 8px;
        padding: 7px 35px;
        margin-top: 20px;
        cursor: pointer;
        border: 1px solid $button-blue;

        @extend .step-button-font;
    }

    .complete {
        margin-top: 20px;
    }

    @media (max-width: 767px) {
        .inner-section {
            padding: 30px 20px;
        }
    }
}
