
.body-container {
    .link-box {
        display: flex;
        align-items: center;
        .icon-inline {
            padding: 0 7px;
            cursor: pointer;
        }
        .link-copy {
            padding: 0 7px;
            text-align: left;
            cursor: pointer;
            text-decoration: underline;
        }

        &.disabled {
            pointer-events: none;
        }
    }
}
