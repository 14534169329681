
@import "@/scss/font";

.quiz-finish {
    margin-bottom: 40px;
    max-width: 80%;
    text-align: center;

    h1 {
        font-size: 54px;
        font-weight: bolder;
    }
    .grade {
        color: yellow;
    }
    .body-message {
        max-width: 420px;
        margin: 20px auto;
    }
    button.button {
        margin: auto;
    }
}
