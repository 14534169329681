
@import "@/scss/color";

.nav-summary-component {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    z-index: 99;
    top: 148px;
    position: fixed;
    background: $nav-summary-background;

    padding: 10px 40px 30px 40px;
    transform: translateX(-50%);
    left: 50%;

    .nav-summary-item {
        border-radius: 50%;
        border: 3px solid $nav-summary-circle;
        margin-right: 15px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner {
            cursor: pointer;
        }

        &.nav-summary-section {
            width: 22px;
            height: 22px;

            .inner {
                width: 12px;
                height: 12px;
            }
        }
        &.nav-summary-module {
            width: 14px;
            height: 14px;
            //display: none;

            .inner {
                width: 6px;
                height: 6px;
            }
        }

        &.locked {
            background-color: transparent;
            border-color: $nav-summary-circle-locked;
            opacity: 0.6;
            .inner {
                cursor: inherit;
            }
        }

        &.complete {
            background-color: $nav-summary-circle;

            &:not(.active) .inner {
                border-radius: 50%;
                border: 2px solid $nav-summary-background;
            }
        }

        &.active {
            background-color: transparent;
            color: $nav-summary-circle-active;

            .inner {
                border-radius: 50%;
                box-sizing: border-box;
                background-color: $nav-summary-circle-active;
            }
        }
        &.nav-summary-section-active .hover-text {
            color: $nav-summary-circle-active;
        }

        .hover-text {
            color: $nav-summary-circle;
            font-size: 13px;
            line-height: 1.3;
            text-align: center;
            position: absolute;
            top: 25px;
            margin: auto;
            left: 50%;
            transform: translateX(-50%);
            //white-space: nowrap;
            width: 75px;
        }
    }

    .nav-summary-item.nav-summary-section
        + .nav-summary-item.nav-summary-section {
        margin-left: 40px;
        &::before {
            width: 43px;
            left: -43px;
        }
    }
    .nav-summary-item.nav-summary-module
        + .nav-summary-item.nav-summary-section {
        margin-left: 5px;
    }
    .nav-summary-item.nav-summary-module {
        margin-right: 10px;
        &::after {
            right: -13px;
            width: 10px;
        }
    }
    .nav-summary-item.nav-summary-module
        + .nav-summary-item.nav-summary-module {
        margin-left: 0px;
        &::before {
            width: 0px;
            left: 0px;
        }
    }
    .nav-summary-item::before,
    .nav-summary-item::after {
        content: "\a0";
        position: absolute;
        width: 20px;
        height: 2px;
        background: $nav-summary-circle;
        z-index: -1;
    }
    .nav-summary-item::before {
        left: -20px;
    }
    .nav-summary-item::after {
        right: -20px;
    }

    .nav-summary-item:first-child::before,
    .nav-summary-item:last-child::after {
        display: none;
    }

    .nav-summary-item:last-child {
        margin-right: 0;
    }

    @media (max-width: 767px) {
        display: none;
    }
}
