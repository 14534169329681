
@import "@/scss/font";

.question-component {
    margin-bottom: 40px;
    max-width: 60%;

    .question-title {
        font-size: 22px;
        font-wieght: bold;
    }
    .question-body {
        font-size: 22px;
        font-wieght: bold;
        margin-bottom: 20px;
    }
    .question-answers {
        margin-bottom: 10px;
        margin-left: 5px;
    }
    .answer {
        border-radius: 20px;
        padding: 5px;
        margin: 5px 0;
    }
    .highlight {
        background-color: #ccaf5f;
        border-radius: 20px;
    }
    .question-after-answer {
        font-size: 14px;
        line-height: 20px;
        padding: 10px 20px;
        margin-bottom: 25px;
    }
    .btn-area {
        display: flex;
        justify-content: center;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 60px;
        cursor: pointer;
        line-height: 30px;
        display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border: 3px solid #000;
        border-radius: 100%;
        background: transparent;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 24px;
        height: 24px;
        background: #ccaf5f;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 100%;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }
}

.btn.default-cursor {
    cursor: default !important;
}
