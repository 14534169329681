
@import "@/scss/font";
@import "@/scss/color";

.recover-password-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: $text-primary;

    .footnote {
        //font-size: 12px;
        //padding-top: 20px;
        width: 100%;
        margin-bottom: -100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .recover-password-content {
        position: relative;
        width: 100%;
        max-width: 387px;
        margin: 0 auto;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        .blur-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            // filter: blur(10px);
        }

        h1.form-header {
            font-size: 43px;
            font-weight: bold;
            padding: 20px 0;
            color: #000;
            max-height: 83px;
        }

        .form-container {
            width: 100%;
            max-width: 387px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
                max-width: 247px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                padding: 0 0 20px 0;
                font-weight: 300;

                .forgotPasswordLink {
                    font-size: 12px;
                }
            }

            &.form-body > * {
                padding: 18px 69px 40px 69px;
                width: 350px;
                margin: auto;

                .form * {
                    margin: 5px 0;
                }
                input {
                    width: calc(100% - 4px);
                    height: 40px;
                    font-size: 16px;
                    text-align: center;
                    border: none;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
            }

            .recover-password-footer {
                padding: 13px 0 6px 0;
                //margin: 0px;
                font-size: 13px;
                text-align: center;
                margin: -5px 0 0 0;
                color: $text-footer;

                p {
                    width: 100%;
                    font-size: 12px;
                }
            }
        }

        .recover-password-footer {
            padding: 13px 0 6px 0;
            margin: 0px;
            font-size: 13px;
            text-align: center;
        }
        .register-link {
            margin-left: 56px;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: $text-primary;
        }

        @media (max-width: 767px) {
        }
    }
}
