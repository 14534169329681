@import '../font';

.module {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 30px 0 30px;
  box-sizing: border-box;
  max-width: 1100px;
  margin: 0 auto;

  h1 {
    @extend .module-heading;
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;

    .step-button {
      margin-right: 17px;
    }

    .step-button:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 20px 20px;
    .buttons {
      margin-top: 15px;
    }
  }

}