@import '../font';

.icon {
  background-repeat: no-repeat;
  background-position: 50%;
  width: 72px;
  height: 72px;
  margin: 0;
  padding: 0;
}
.video-icon {
  background-repeat: no-repeat;
  background-position: 50%;
  width: 72px;
  height: 72px;
  margin: 0;
  padding: 0;

}
.icon-doc, .icon-docx { background-image: url('~@/scss/images/icon-doc.png'); }
.icon-pdf { background-image: url('~@/scss/images/icon-pdf.png'); }
.icon-ppt, .icon-pptx { background-image: url('~@/scss/images/icon-ppt.png'); }
.icon-xls, .icon-xlsx { background-image: url('~@/scss/images/icon-xls.png'); }
.icon-web, .icon-webx { background-image: url('~@/scss/images/icon-web.png'); }
.icon-mp4, .icon-mp4x { background-image: url('~@/scss/images/video-resource.png'); }
