
@import "@/scss/color";

.module.copy-video {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    max-width: 100%;
    padding: 0;

    .content-container {
        align-items: center;

        // Need extra spacing to not run into absolutely positioned nav summary
        padding-top: 80px;
    }

    .play-button {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 70px;
        height: 70px;
    }

    h1,
    p {
        max-width: 600px;
        width: 100%;
    }

    .buttons {
        margin-bottom: 20px;
    }

    .video-subtext {
        color: $module-summary-circle-locked;
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    @media (max-width: 767px) {
        .play-button {
            width: 70px;
            height: 70px;
        }
    }
}
