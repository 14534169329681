
@import "@/scss/font";

li {
    span {
        cursor: pointer;
    }
}
#right-header-section {
    margin-left: auto;
}
#nav-header {
    z-index: 100;
    justify-content: flex-start;

    .menuItemsClass {
        overflow-y: auto;
    }

    .more-nav {
        margin-right: 25px;

        .more-items {
            margin: 0 -5px;
            display: flex;
        }

        .more-item {
            padding: 0 5px;

            a {
                @extend .more-nav-font;
                text-decoration: none;
                font-size: 15px;
                margin: 0 10px;
            }
        }
    }

    @media (max-width: 767px) {
        .more-nav {
            display: none;
        }
    }
}
