
@import "@/scss/color";

.modules {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    min-width: 100vw;

    .module-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .module.locked {
        filter: blur(2.2px);
    }
}
