
.resource-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    .resource-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.header-container {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .body-container {
            width: 100%;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
        }
        a.box {
            width: 300px;
            height: 50px;
            border: 1px solid #ccc;
            margin: 10px 15px;
            background-color: transparent;
            color: #58595b;
            display: flex;
            text-decoration: none;
        }
        .inline {
            width: 50px;
            border-right: 1px solid #ccc;
            padding: 5px 8px;
            display: inline-block;
        }
        .copy {
            flex: 1;
            line-height: 48px;
            text-align: left;
            padding-left: 25px;
            overflow: none;
        }
    }

    @media (max-width: 767px) {
        h1 {
        }
    }
}
