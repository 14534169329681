
@import "@/scss/font";
@import "@/scss/color";

.resource-layout-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    .resource-page-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        overflow: hidden;

        background-color: $background-color;
        background-size: cover;
        background-position: center 0;
    }
    .resource-container {
        height: 100%;
        overflow: auto;
        justify-content: flex-start;
    }
    .resource-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;
    }

    h1.header-container {
        font-size: 40px;
        padding: 30px;
        line-height: 50px;
        margin-top: 30px;
        //font-weight: bold;
    }

    .body-container {
        width: 50%;
        color: white;
        padding-top: 15px;
        text-align: center;
        justify-content: center;
        align-content: center;
        font-size: 16px;
        display: inline-block;
        flex-wrap: wrap;
    }
    a.box {
        width: 100%;
        height: 50px;
        //margin: 10px 15px;
        background-color: transparent;
        //color: #58595b;
        display: flex;
        //text-decoration: none;
    }
    .inline {
        width: 50px;
        padding: 5px 8px;
        display: inline-block;
    }
    .copy {
        flex: 1;
        color: white;
        cursor: pointer;
        text-decoration: underline;
        line-height: 48px;
        text-align: left;
        padding-left: 0px;
        overflow: none;
    }
}

@media (max-width: 767px) {
    .resource-layout-page .resource-container {
        width: 100%;
    }
    .resource-layout-page .resource-layout-content {
        width: 85%;
    }
    .resource-layout-page h1.header-container {
        padding: 0px;
        font-size: 30px;
    }
    .body-container {
        width: 100% !important;
    }
    .resource-layout-page a.box {
        width: 100%;
    }
    .resource-layout-page .copy {
        line-height: 20px;
        padding-top: 11px;
    }
}
@media (max-width: 1024px) {
    .resource-layout-page .copy {
        line-height: 20px;
        padding-top: 12px;
    }

    .resource-layout-page .resource-container {
        width: 90%;
    }
}
