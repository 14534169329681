
@import "@/scss/font";
@import "@/scss/color";

.contact-us-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    .contact-us-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.header-header {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .body-container {
            width: 100%;
            max-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
        }
        .form {
            margin-bottom: 25px;
            width: 100%;
        }
        textarea {
            width: 400px;
            height: 100px;
        }

        .support-button {
            box-sizing: border-box;
            color: white;
            @extend .support-button-font;
            line-height: 1.3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 0;
            padding: 0;

            .arrow {
                background-image: url("../../assets/images/step-button-arrow-right.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }
    }

    @media (max-width: 767px) {
        h1 {
        }
    }
}
