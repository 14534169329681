@import 'breakpoints';

.circle-text {
  font-size: 37px;
  font-weight: 600;
}

.tabbed-content-header {
  font-size: 50px;
  font-weight: 300;
}

.boxes-header {
  font-size: 57px;
  font-weight: 300;
}

.step-button-font {
  font-size: 18px;
  font-weight: 500;
}

.lock-text {
  font-size: 33px;
  font-weight: 300;
  line-height: 1.2;
}

.p-font {
  font-size: 16px;
}

.final-heading {
  font-size: 55px;
  font-weight: 300;
}

.module-heading {
  font-size: 40px;
}

.final-secondary-heading {
  font-size: 30px;
  font-weight: 300;
}


.h1-font {
  font-size: 48px;
  font-weight: 300;
}

.support-button-font {
  font-size: 20px;
  font-weight: 500;
}

.more-nav-font {
  font-size: 14px;
}

@media (max-width: calc(map-get($grid-breakpoints, 'md') - 1px)) {
  .circle-text {
    font-size: 18.5px;
  }

  .tabbed-content-header {
    font-size: 18px;
  }

  .boxes-header {
    font-size: 20px;
  }

  .step-button-font {
    font-size: 9px;
  }

  .lock-text {
    font-size: 16.5px;
    line-height: 1.2;
  }

  .h1-font {
    font-size: 35.5px;
}

  .p-font {
    font-size: 14px;
  }

  .final-heading {
    font-size: 20px;
  }

  .final-secondary-heading {
    font-size: 18px;
  }

  .support-button-font {
    font-size: 14px;
  }

  .more-nav-font {
    font-size: 14px;
  }
}

@media (max-width: calc(map-get($grid-breakpoints, 'lg') - 1px)) {
	.p-font {
		font-size: 18px;
	}
}
