
@import "@/scss/color";
@import "@/scss/font";

.module.three-boxes {
    h1 {
        width: 100%;
    }

    h2 {
        @extend .boxes-header;
    }

    .boxes {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        width: 100%;
    }

    .box {
        display: flex;
        width: 100%;
        max-width: 30%;

        min-height: 180px;

        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        background-color: $grey;
        border-radius: 10px;
        text-align: center;
        padding: 20px 32px;
        margin-right: 30px;

        font-size: 12px;

        > * {
            width: 100%;
        }

        h2 {
            font-size: 30px;
        }
    }

    .box:last-child {
        margin-right: 0;
    }

    @media (max-width: 767px) {
        .boxes {
            flex-wrap: wrap;
        }

        .box {
            max-width: 400px;
            min-height: 128px;
            border-radius: 5px;
            padding: 10px 16px;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}
