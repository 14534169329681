
@import "@/scss/font";
@import "@/scss/color";

.reward-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: 0;
    flex: 1;
    position: relative;
    overflow-y: auto;
    align-items: center;
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    min-width: 100vw;

    .content-container {
        flex: 1;
    }

    .reward-content {
        width: 100%;
        max-width: 750px;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.form-header {
            font-size: 24px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .form-container {
            width: 100%;
            min-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
            }
            .error {
                width: 100%;
                color: #ccaf5f;
                font-weight: bold;
                margin-bottom: 1rem;
            }
            .success-message {
                margin-bottom: 1rem;
            }

            &.form-body > * {
                .form {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-end;
                }
                .form-group {
                    width: 100%;
                }
                .form-col {
                    width: calc(50% - 10px);
                }
                .product-description {
                    height: 70px;
                }
                .form * {
                    margin: 5px 0;
                }
                img {
                    max-width: 300px;
                    max-height: 300px;
                }
                input,
                select {
                    width: calc(100% - 4px);
                    height: 25px;
                    font-size: 16px;
                    text-align: center;
                    border: none;
                    border-bottom: #ccaf5f 1px solid;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
                select {
                    width: 100%;
                    height: 44px;
                }
                select.placeholder-font {
                    color: $font-placeholder;
                }
                input.half,
                select.half {
                    width: calc(50% - 10px);
                }
                button.half,
                input[type="submit"].half {
                    width: calc(50% - 6px);
                }
                .register-footer {
                    margin: -5px 0 0 0;
                    color: $text-footer;
                }

                .full-width {
                    width: 100%;
                }
                .left {
                    float: left;
                }
                .right {
                    float: right;
                }
            }
        }

        .reward-footer {
            padding: 13px 0 0 0;
            margin: 0px;
            font-size: 13px;
        }

        input[type="radio"] {
            position: absolute;
            left: -10000px;
            top: -10000px;
            width: 1px;
            height: 1px;
            visibility: hidden;
        }

        input[type="radio"] + label {
            display: block;
        }

        input[type="radio"] + label::after {
            content: " ";
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 20px;
            background-color: #dedede;
            margin: auto;
        }

        input[type="radio"]:checked + label::after {
            background-color: #ccaf5f;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: $text-primary;
        }

        @media (max-width: 767px) {
            .reward-content .form-container.form-body > * .form-col {
                width: 100%;
            }
            .reward-content .form-container.form-body > * .form {
                min-width: 100%;
                flex-wrap: wrap;
            }
        }
    }
}
@media (max-width: 767px) {
    .reward-page {
        top: 60px;
        height: calc(100vh - 60px);
        min-height: calc(100vh - 60px);
    }
}
