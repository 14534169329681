
@import "@/scss/color";

.welcome-simple {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 30px 20px;

    h1 {
        margin-bottom: 20px;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
    }

    @media (max-width: 767px) {
    }
}
