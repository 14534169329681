
@import "@/scss/color";

.arrow-controls {
    .arrows {
        display: flex;
    }

    .arrow {
        cursor: pointer;
        width: 82px;
        height: 82px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        &.left {
            position: fixed;
            left: 30px;

            .arrow-icon {
                background-image: url("../../assets/images/arrow-left.png");
                width: 82px;
                height: 82px;
                background-size: cover;
            }
        }

        &.right {
            position: fixed;
            right: 30px;

            .arrow-icon {
                background-image: url("../../assets/images/arrow-right.png");
                width: 82px;
                height: 82px;
                background-size: cover;
            }
        }
    }
    @media (max-width: 767px) {
        .arrow {
            &.left {
                left: 0px;
            }
            &.right {
                right: 0px;
            }
            .arrow-icon {
                transform: scale(0.6);
            }
        }
    }
}
