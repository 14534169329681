
.modal {
    display: none;
    padding: 35px;
    /*padding-bottom: 15px;*/
}

.vex {
    z-index: 10000 !important;

    .modal {
        display: block;
    }
}
