
#testimonial-footer {
    z-index: 99;

    .heading,
    .button {
        flex-shrink: 0;
    }

    .description {
        max-width: 800px;
    }
}
