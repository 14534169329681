
@import "@/scss/color";

.page-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    background-color: $background-color;
    background-size: cover;
    background-position: center 0;
    justify-content: center;
    align-items: stretch;
}
.content-container {
    overflow: auto;
    justify-content: flex-start;
}
.page-layout-content {
    width: 80%;
    margin: auto;
}

h1.header {
    font-size: 40px;
    padding: 30px;
    line-height: 50px;
    margin-top: 30px;
}

p {
    margin-bottom: 1rem;

    &.intro {
        margin-bottom: 2rem;
        font-size: 18px;
        text-align: center;
    }
}

.error {
    color: $primary-color;
}

form {
    display: block;
    margin: 0 0 1rem 0;
    border-top: 1px solid #000;
    padding-top: 2rem;
}

.response-saved {
    text-align: center;
    margin: 0 0 1rem 0;
}

.alert {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 5px solid white;
    color: #000;

    &.alert-success {
        background-color: #c6fdc6;
        border-color: #287528;
    }

    &.alert-error {
        background-color: #efaab5;
        border-color: #a02e2e;
    }
}

@media (max-width: 767px) {
    .page-container {
        width: 100%;
    }
    .page-layout-content {
        width: 85%;
    }
    h1.header {
        padding: 0px;
        font-size: 30px;
    }
}
@media (max-width: 1024px) {
    .page-container {
        width: 90%;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
