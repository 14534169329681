
@import "@/scss/color";

.summary-navigation {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;

    .item {
        border-radius: 50%;
        border: 3px solid $carousel-button;
        width: 21px;
        height: 21px;
        margin-right: 15px;
        box-sizing: border-box;

        &.active {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            .inner {
                border-radius: 50%;
                box-sizing: border-box;
                width: 13px;
                height: 13px;
                background-color: $carousel-button;
            }
        }
    }

    .item:last-child {
        margin-right: 0;
    }

    @media (max-width: 767px) {
        margin-top: 20px;

        .item {
            border-width: 1.5px;
            width: 10.5px;
            height: 10.5px;
            margin-right: 7.5px;

            &.active {
                .inner {
                    width: 6.5px;
                    height: 6.5px;
                }
            }
        }
    }
}
