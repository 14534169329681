@font-face {
	font-family: 'Avenir';
	src: url('~@/scss/fonts/Avenir-Medium-09.ttf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir';
	src: url('~@/scss/fonts/Avenir-Black-03.ttf');
	font-weight: bold;
	font-style: normal;
}

$font-stack: 'Avenir', Arial, Helvetica, sans-serif;

@import 'color';

@import 'helper/mixin';

@import 'helper/_reset';

html * {
	font-family: $font-stack;
}

@import 'helper/_layout';

@import 'helper/_nav_menu';

@import 'helper/transition';

@import 'components/modal';

@import 'components/module';

@import 'components/icons';

@import 'components/form';
