
@import "@/scss/font";
@import "@/scss/color";

.policy-modal-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    table td {
        border-collapse: collapse;
        border: 1px solid lightgrey;
        padding: 5px;
    }

    .policy-modal-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.header-container {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .body-container {
            width: 100%;
            font-size: 16px;
            text-align: left;
        }
    }

    @media (max-width: 767px) {
        h1 {
        }
    }
}
