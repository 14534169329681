
@import "@/scss/color.scss";

.section {
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
}

.section-wrapper {
    width: 100%;
}

.section-data {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
