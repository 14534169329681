
@import "@/scss/color";

.content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.program-landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-size: 16px;

    h1 {
        margin-bottom: 20px;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
        margin: auto;
    }

    .imagebutton {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        width: 100%;
        padding-top: 30px;
        flex-wrap: wrap;

        img {
            max-width: 200px;
        }
    }
    .circle.inner {
        background-color: #ccaf5f;
        //border: 2px solid white;
        width: 224px;
        height: 69px;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        color: #000;
        text-transform: uppercase;
    }
    .circle.outter {
        margin: 20px;
        cursor: pointer;
        border: none;
        padding: 0;
    }

    @media (max-width: 767px) {
        h1 {
            font-size: 60px;
        }
    }
}
