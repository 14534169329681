
@import "@/scss/color";

.carousel {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .circle {
        position: relative;
        width: 317px;
        height: 317px;
        border-radius: 50%;
        border: 8px solid $grey;
        background-color: $primary-color;
        text-align: center;
        flex-shrink: 0;

        > span {
            display: block;
            padding: 0 40px;
            box-sizing: border-box;
            font-size: 12px;
        }

        > img {
            width: 317px;
            height: 317px;
        }
    }

    .arrow {
        position: absolute;
        width: 73px;
        height: 73px;
        border: 3px solid $grey;
        background-color: $carousel-button;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        top: 0;
        bottom: 0;
        margin: auto 0;

        &.left {
            left: -41px;
            padding-right: 3px;

            .arrow-icon {
                background-image: url("../../assets/images/carousel-arrow-left.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        &.right {
            right: -41px;
            padding-left: 3px;

            .arrow-icon {
                background-image: url("../../assets/images/carousel-arrow-right.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }
    }

    @media (max-width: 767px) {
        justify-content: center;

        .circle {
            width: 211.5px;
            height: 211.5px;
            border-width: 4px;

            font-size: 8px;

            > span {
                padding: 0 20px;
                font-size: 9px;
            }

            img {
                width: 211.5px;
                height: 211.5px;
            }
        }

        .arrow {
            width: 36.5px;
            height: 36.5px;
            border-width: 1.5px;

            &.left {
                left: -20.5px;
                padding-right: 1.5px;

                .arrow-icon {
                    width: 7.5px;
                    height: 13px;
                }
            }

            &.right {
                right: -20.5px;
                padding-left: 1.5px;

                .arrow-icon {
                    width: 7.5px;
                    height: 13px;
                }
            }
        }
    }
}
