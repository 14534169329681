
@import "@/scss/font";

#footer-logo {
    z-index: 100;
    width: 200px;
    min-height: 47px;
    position: absolute;
    bottom: 80px;
    right: 20px;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
        display: none;
    }
}
