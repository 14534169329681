
.module.activity-video {
    max-width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0px;
    flex-direction: row;
    align-items: stretch;
    padding: 0;

    .content-container {
        align-items: center;

        // Need extra spacing to not run into absolutely positioned nav summary
        padding-top: 80px;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
    }

    .center-item {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 190px;
        height: 190px;
        position: absolute;
    }

    .image-splash {
        max-width: 508px;
        max-height: 100%;
        position: relative;
        top: 0;
        left: 0;
    }

    .image-button-container {
        padding-top: 25px;
        padding-bottom: 21px;
        max-width: 508px;
        width: 100%;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        text-align: center;
    }
}
