
.slideshowContainer {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
}

.slides {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: auto;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    display: flex;
}

/* Animations for slide stacking in */

.stack-in-enter-active {
    transition: all 0.5s linear;
    z-index: 3;
}

.stack-in-enter-from {
    transform: translateX(-100%);
}

.stack-in-enter-to {
    transform: translateX(0%);
}

.stack-in-leave-active {
    transition: all 0.5s;
    z-index: 0;
}

/* Animations for slide stacking out */

.stack-out-leave-active {
    transition: all 0.5s linear;
    z-index: 2;
}

.stack-out-leave-from {
    transform: translateX(0%);
}

.stack-out-leave-to {
    transform: translateX(-100%);
}

.stack-out-enter-active {
    z-index: 1;
}

/* Animations for fade */

.fade-leave-active {
    transition: all 0.5s linear;
    z-index: 2;
}

.fade-leave-from {
    opacity: 100%;
}

.fade-leave-to {
    opacity: 0%;
}

/*  */

.slideNavContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    pointer-events: none;

    .sideButtonContainer {
        width: 82px;
        height: 82px;
        pointer-events: auto;
        margin: 30px;

        .sideButton {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .sideButton.left {
            background-size: cover;
            background-image: url("../../assets/images/arrow-left.png");
        }

        .sideButton.right {
            background-size: cover;
            background-image: url("../../assets/images/arrow-right.png");
        }
    }

    .navButtonContainer {
        display: flex;
        pointer-events: auto;
        align-self: flex-end;
        margin: 20px;

        .navButton {
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            cursor: pointer;
            opacity: 0.5;
            margin: 4px;
            border: 1px solid #888;
            border-radius: 15px;
            box-sizing: border-box;
        }

        .navButton.active {
            opacity: 1;
            cursor: default;
        }
    }
}
