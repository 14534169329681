
@import "../../../scss/color";

.question {
    margin-bottom: 1rem;

    &.last {
        margin-bottom: 1.5rem;
    }
}
label {
    display: block;
    margin-bottom: 1rem;
}
textarea {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.2;
    border: 1px solid $grey;
    box-sizing: border-box;
}
