
.module.blank {
    .images {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 767px) {
        .images {
            flex-wrap: wrap;
            img {
                height: auto;
                width: 100%;
            }
        }
    }
}
