
@import "@/scss/color";
@import "@/scss/breakpoints";

.welcome-simple-template {
    background-color: #00b2ed;
}

.welcome-complex-template {
    background-color: #00b2ed;
}

.modules-template {
}

.customer-testimonials-template {
    background-color: $primary-color;
}

.contact-us-template {
    background-color: $background-grey;
}

.faq-template {
    background-color: $background-grey;
}

.nav-summary {
    display: flex;
    justify-content: center;
}

#main-page .scroll-down {
    position: fixed;
    top: calc(50% - 40px);
    z-index: 99;

    @media (max-width: 767px) {
        top: 50px;
        display: none;
    }
}

.page-fade-enter-active,
.page-fade-leave-active {
    transition: opacity 0.2s;
}
.page-fade-enter-from,
.page-fade-leave-to {
    opacity: 0;
}

// Need to make sure container does not overlap fixed nav-summary-mobile
@media (max-width: calc(map-get($grid-breakpoints, "md") - 1px)) {
    #main-container {
        padding-top: 60px;
    }
}
