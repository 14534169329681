.slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-to {
    transform: translateY(-100px);
    opacity: 0;
}

.slide-fade-enter-from {
    transform: translateY(-100px);
    opacity: 0;
}

.slide-fade-leave-from, .slide-fade-enter-to {
    transform: translateY(0);
    opacity: 1;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-leave-to, .fade-enter-from {
    opacity: 0;
}

.fade-leave-from, .fade-enter-to {
    opacity: 1;
}
