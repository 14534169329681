
.module.three-sections {
    .icon {
        margin-bottom: 10px;
    }

    h1 {
        text-align: left;
    }

    .buttons {
        justify-content: flex-start;
    }

    .columns {
        display: flex;
        width: 100%;

        .column {
            width: 50%;
            margin-right: 60px;

            &.left {
                p {
                    margin-bottom: 20px;
                }
            }
        }

        .column:last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 971px) {
        .carousel-column {
            transform: scale(0.7);
        }
    }

    @media (max-width: 767px) {
        .carousel-column {
            transform: scale(1);
        }

        .icon {
            margin-bottom: 5px;
            > img {
                transform: scale(0.5);
            }
        }

        .columns {
            display: flex;

            .column {
                margin-right: 0;
                width: 100%;
            }

            .column:last-child {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        .columns {
            flex-wrap: wrap;
            flex-direction: column-reverse;
            .column {
                width: 100%;
            }
        }

        .buttons {
            justify-content: center;
        }

        .column.carousel-column {
        }
    }
}
