
@import "@/scss/color";

.accordion-item {
    cursor: pointer;
    .accordion-before-title {
        width: 18px;
        height: 18px;
        margin-right: 16px;
        position: relative;
        top: 2px;
        background-size: cover;
    }
    .accordion-title {
        font-size: 20px;
        font-weight: 500;
        padding: 15px 38px;
        display: flex;
    }
    .accordion-body {
        font-size: 13px;
    }
}
.accordion-item:not(.active) {
    .accordion-before-title {
        background-image: url("../../assets/images/step-button-plus.png");
    }
    .accordion-body {
        height: 0;
        max-height: 0;
        padding: 0;
        visibility: hidden;
        transition: all 0s step-end;
        transition-property: visibility, height, padding;
    }
}
.accordion-item.active {
    .accordion-before-title {
        background-image: url("../../assets/images/step-button-minus.png");
    }
    .accordion-body {
        height: auto;
        visibility: visible;
        padding: 0 0 15px 72px;
        transition: all 0.5s linear;
        transition-property: max-height, visibility;
        max-height: 3000px;
    }
}
