
.section-container {
    border-bottom: 1px solid #000;
    padding: 20px;
    .header-content {
        font-size: 30px;
    }
    &:last-child {
        border-bottom: none;
    }
}

.header-section {
    display: block;
}

@media (max-width: 767px) {
    .section-container .header-content {
        font-size: 24px;
    }
}
