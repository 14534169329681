
@import "@/scss/color";
@import "@/scss/helper/_nav";
@import "@/scss/breakpoints";

.nav-summary-mobile-component {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    z-index: 99;
    top: $nav-header-height-mobile;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    font-size: 25px;
    display: none;

    .toggle {
        position: fixed;
        right: 15%;
        color: white;
    }
    .control-btn {
        position: fixed;
        width: 30px;
        color: white;
    }
    .right-btn {
        right: 10px;
    }
    .left-btn {
        left: 10px;
    }
    .nav-summary-mobile-item {
        color: lightgrey;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        width: 100%;

        &.locked {
            color: white;
            opacity: 0.6;
        }

        &.complete {
            color: white;
        }

        &.active {
            color: white;
        }

        &.nav-summary-mobile-module {
            font-size: 16px;
        }

        &.nav-summary-mobile-section:first {
            border: none;
        }
        &.nav-summary-mobile-section.show-all {
            border-top: 2px solid lightgrey;
        }
        &.show-all {
            padding: 3px;
        }
    }
    &.show-all {
        justify-content: flex-start;
        height: 100%;
    }

    @media (max-width: calc(map-get($grid-breakpoints, "md") - 1px)) {
        display: flex;
    }
}
