
video {
    width: 100%;
    display: block;
}
.video-js vjs-default-skin vjs-16-9 {
    margin-left: 40%;
    margin-top: 22%;
}
.video {
    &::cue,
    &::-webkit-media-text-track-display-backdrop {
        // if this doesn't work then own implementation will need
        // https://stackoverflow.com/questions/32252337/how-to-style-text-tracks-in-html5-video-via-css
        background-color: rgba(83, 86, 90, 0.7) !important;
        margin-left: 40%;
        margin-top: 22%;
    }
}
