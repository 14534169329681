
@import "@/scss/color";

.customer-testimonial {
    padding: 20px 50px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .testimonial-carousel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        > .arrow {
            width: 36px;
            height: 36px;
            background-color: $carousel-button;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            &.left {
                padding-right: 3px;
                margin-right: 10px;

                .arrow-icon {
                    background-image: url("../../../assets/images/carousel-arrow-left.png");
                    width: 8px;
                    height: 13px;
                    background-size: cover;
                }
            }

            &.right {
                padding-left: 3px;
                margin-left: 10px;
                .arrow-icon {
                    background-image: url("../../../assets/images/carousel-arrow-right.png");
                    width: 8px;
                    height: 13px;
                    background-size: cover;
                }
            }
        }
    }

    h1 {
        margin-bottom: 50px;
    }

    .testimonials {
        max-width: 890px;
        width: 100%;
    }

    .testimonial-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .testimonial-item {
        width: 100%;
    }

    @media (max-width: 767px) {
        padding: 20px 10px;

        h1 {
            margin-bottom: 20px;
        }

        .arrow {
            width: 36.5px;
            height: 36.5px;
            border-width: 1.5px;

            &.left {
                left: -20.5px;
                padding-right: 1.5px;
                margin-right: 5px;

                .arrow-icon {
                    width: 7.5px;
                    height: 13px;
                }
            }

            &.right {
                right: -20.5px;
                padding-left: 1.5px;
                margin-left: 5px;

                .arrow-icon {
                    width: 7.5px;
                    height: 13px;
                }
            }
        }
    }
}
