@import '@/scss/color';
@import '@/scss/breakpoints.scss';
@import "../helper/mixin";

form {
    // Implementing default class to establish default styles without affecting existing site.
    &.default {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        ::placeholder {
            color: $font-placeholder;
        }

        ::-ms-input-placeholder {
            color: $font-placeholder;
        }

        :-ms-input-placeholder {
            color: $font-placeholder;
        }

        select, input {
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 16px;
            border: none;
            height: 44px;
            line-height: 44px;
            border-radius: 0;
            color: $text-primary;
            @include input-outline;
        }

        select {
            background: #fff url("~@/assets/images/select-arrow-black.png") no-repeat top 17px right 17px;
            -webkit-appearance: none;
            appearance: none;

            &:invalid {
                color: $font-placeholder;
            }
        }

        .form-group {
            margin-bottom: 1.687rem;
            width: 100%;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .form-row#{$infix} {
            width: 100%;
            display: flex;

            .form-group {
                width: auto !important;
                flex: 1 1 auto;
                margin-left: 0.8rem;
                margin-right: 0.8rem;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.form-group {
    .error {
        display: none;
        margin-top: 0.5rem;
        color: $error-color;
    }

    :invalid + .error, .is-invalid + .error {
        display: block;
    }
}
