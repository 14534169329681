
@import "@/scss/font";
@import "@/scss/color";

.profile-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    .profile-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 23px 32px;
        box-sizing: border-box;

        h1.form-header {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .form-container {
            width: 100%;
            min-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
            }
            .error {
                display: block;
                width: 100%;
                font-size: 12px;
                color: #ccaf5f;
            }

            &.form-body > * {
                .form {
                    min-height: 700px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .form-group {
                    width: 100%;
                }
                .form-col {
                    width: calc(48% - 10px);
                }
                .form * {
                    margin: 5px 0;
                }
                input,
                select {
                    width: calc(100% - 4px);
                    height: 40px;
                    font-size: 16px;
                    //text-align: center;
                    border: none;
                    border-bottom: black 1px solid;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
                select {
                    width: 100%;
                    height: 49px;
                }
                select.placeholder-font {
                    color: $font-placeholder;
                }
                input.half,
                select.half {
                    width: calc(50% - 10px);
                }
                button.half,
                input[type="submit"].half {
                    width: calc(50% - 6px);
                }
                .register-footer {
                    margin: -5px 0 0 0;
                    color: $text-footer;
                }

                .full-width {
                    width: 100%;
                }
                .left {
                    float: left;
                }
                .right {
                    float: right;
                }
            }
        }

        .register-footer {
            padding: 13px 0 0 0;
            margin: 0px;
            font-size: 13px;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: $text-primary;
        }

        .btn-large {
            background-color: #a4a4a4;
            width: 100%;
            height: 100%;
        }

        .dropdowntext {
            text-align: center;
            text-align-last: center;
        }
        .text {
            float: left;
            color: #aeaeae;
        }

        @media (max-width: 767px) {
            .profile-content .form-container.form-body > * .form-col {
                width: 100%;
            }
            .profile-content .form-container.form-body > * .form {
                min-width: 100%;
                flex-wrap: wrap;
            }
        }
        @media (max-width: 500px) {
            .form-container {
                min-width: 100%;
                &.form-body .form {
                    flex-direction: column;
                }
                &.form-body .form-col {
                    width: 100%;
                }
            }
        }
    }
}
