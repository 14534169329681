
@import "@/scss/font";
@import "@/scss/color";

.partner-wrapper {
    display: flex;
    flex: 1 0;
    justify-content: center;
    width: 100%;
    color: #000;
    overflow-y: auto;
    bottom: 0;
    background-color: $background-color;
    background-size: cover;
    background-position: center 0;

    .right-arrow-wrapper {
        cursor: pointer;
    }

    .content-container {
        h1.header-header {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }
        .blur-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            // filter: blur(10px);
            background-repeat: no-repeat;
            background-size: 100% 92%;
            background-position: 0 86px;
            background-attachment: fixed;
        }
        .image-splash {
            width: 350px;
            height: 110px;
        }

        .text-splash {
            float: left;
            width: 100%;
            margin: 50px;
        }

        .video-splash {
            height: 280px;
            width: 533px;
        }

        .right-arrow {
            width: 82px;
            height: 82px;
            position: absolute;
            right: 10px;
            top: 50%;
        }

        .body-container {
            width: 100%;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
        }
        a.box {
            width: 300px;
            height: 50px;
            border: 1px solid #ccc;
            margin: 10px 15px;
            background-color: transparent;
            color: #58595b;
            display: flex;
            text-decoration: none;
        }
        .inline {
            width: 50px;
            border-right: 1px solid #ccc;
            padding: 5px 8px;
            display: inline-block;
        }
        .copy {
            flex: 1;
            line-height: 48px;
            text-align: left;
            padding-left: 25px;
            overflow: none;
        }
    }

    .imagebutton {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        width: 100%;
        padding-top: 30px;
        flex-wrap: wrap;

        img {
            max-width: 200px;
        }
    }
    .circle.inner {
        background-color: #ccaf5f;
        color: #000;

        width: 160px;
        height: 67px;
        font-weight: bold;
        text-align: center;
        font-size: 20px;

        text-transform: uppercase;
    }
    .circle.outter {
        margin: 20px;
        cursor: pointer;
    }
    .image-splash {
        max-width: 508px;
        max-height: 100%;
        position: relative;
        top: 0;
        left: 0;
    }

    .image-button-container {
        padding-top: 25px;
        padding-bottom: 21px;
        max-width: 508px;
        width: 100%;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        text-align: center;
    }

    @media (max-width: 767px) {
        .partner-wrapper {
            margin: 0;
            top: 60px;
        }
        .right-arrow-wrapper {
            display: none;
        }
        .content-container {
            .video-splash {
                width: 100%;
            }
        }
    }
}
