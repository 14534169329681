
@import "@/scss/breakpoints";

.privacy-popup {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.9);
    left: 0;
    position: fixed;
    top: 0;
    z-index: 1000;

    .privacy-popup--content {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        text-align: center;
        color: white;
        padding: 1.5rem;

        @media (min-width: map-get($grid-breakpoints, "sm")) {
            width: 500px;
            padding: 0;
        }

        .privacy-popup--button-container {
            margin-top: 20px;
        }

        button {
            padding: 20px 105px;
            font-size: 18px;
            font-weight: bold;
            border: none;
            cursor: pointer;
            margin-top: 20px;
            transition: all 0.2s;
            display: inline-block;
        }
    }
}
