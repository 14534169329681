
@import "@/scss/color.scss";

.spinner-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: 0 0 60px 0 #000000 inset;
    position: absolute;
    top: 0;
    z-index: 10000;

    &__icon {
        fill: $primary-color;
        stroke: $grey;
    }
}
