.vex * {
  //box-sizing: content-box;
}

.vex.vex-theme-plain .vex-content {
  width: 60%;
  padding: 0;
}

.vex.vex-theme-plain .vex-dialog-form .vex-dialog-input {
  margin: 0;
}

.vex.vex-theme-plain .vex-dialog-form .vex-dialog-message {
  margin: 0;
}

.vex.vex-middle-close .vex-overlay {
  background: rgba(0,0,0,.8);
}

.vex.vex-theme-plain .vex-content {
  overflow: auto;
  position: absolute;
  right: 0;
  left: 0;
}


.vex.vex-full-screen .vex-content {
  top: 0;
  bottom: 0;
}

.vex.vex-middle-close .modal {
  padding: 0;
}

.vex.vex-middle-close .vex-close:before {
  content: "X";
  font-size: 50px;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

@media (max-width: 767px) {
  .vex.vex-theme-plain .vex-content {
    width: 100%;
  }
}